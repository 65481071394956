import React, { useRef } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { scrollToRef } from 'common/scrollToRef';
import { LinkData } from 'models/Links';
import { BaseP, HeaderMedium, MediumTextTransparent } from 'components/typography';
import Layout from 'components/Layout';
import { servicesLinks, servicesLinksChinese, servicesLinksKorean, servicesLinksJapanese } from 'components/Layout/navigation';
import Header from './Header';
import TabNavigator from 'components/TabNavigator';
import Process, { TimelineItem } from './Process';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import PickAndPack from 'images/crowdfunding-fulfillment/pick-and-pack.png';
import ProprietyPlatform from 'images/crowdfunding-fulfillment/propriety-platform.png';
import AffordablePrice from 'images/crowdfunding-fulfillment/affordable-price.png';
import CTABG from 'images/crowdfunding-fulfillment/cta-bg.png';
import WeOfferCompetitiveRates from 'images/We-offer-competitive-rates.png';


const BasePa = styled.h3`
  font-size: 14px;
  font-weight: 300;
  color: #00AF85;
  margin-bottom: 35px;

  @media only screen and (min-width: 1024px) {
    font-size: 16px;
  }
`;

const TabNav = styled.div`

  .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }

`;

const Features = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;

  .features-container {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .feature-subtitle {
    font-size: 30px;
    margin-bottom: 15px;
  }

  h2 {
    margin-bottom:45px;
  }

  .column-one-third {
    width: 33%;
    padding-right: 15px;
    padding-left:15px;
    display: inline-block;
    float: left
  }

  .column-two-third {
    width: 66%;
    display: inline-block;
    float: left;
    padding-right: 15px;
    padding-left:15px;
  }

  .row {
    margin-left:-15px;
    margin-right:-15px;
    position:relative;
  }

  .last {
    padding-right: 0; 
  }

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  .features-container .row {
    padding-top:35px;
    padding-bottom:35px;
  }

  .features-image {
    max-width: 250px;
  }

  .features-container p {
    font-size: 18px;
    color: #a4b0ad;
    line-height: 35px;
  }

  ul {
    margin-top: 0;
  }

  li::marker {
    font-size: 25px;
  }

  h4 {
    font-size: 25px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  @media only screen and (max-width:768px) {
    .column-one-third {
      width: 100%;
      margin-bottom:20px;
    }
    .column-two-third {
      width: 100%;
    }
    .features-container .row { 
      padding-top:15px;
      padding-bottom:15px;
    }

    h2 {
      margin-bottom:20px;
    }

    .feature-subtitle {
      font-size:25px;
    }
  }

`;
const Breadcrumbs = styled.p`
    padding: 25px 0px 25px 50px;
    width:100%
`;

const CTA = styled.div`
  position: relative;
  width: 100%;
  .cta-bg {
    position: absolute;
    top: 0;
    left:0;
    object-fit: cover;
    right:0;
    height: 100%;
    width: 100%;
    bottom: 0;
    float:left;
  }

  .cta-container {
    max-width: 1300px;
    padding-left:15px;
    padding-right:15px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-top: 50px;
    padding-bottom: 65px;
    text-align: center;
    color: #fff;
  }

  .cta-container h2 {
    font-size:35px;
    margin-bottom:30px;
  }

  .cta-container a {
    color: #fff;
    margin-top: 30px;
    display: block;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    border: solid 3px #fff;
    border-radius: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
  }

  .cta-container p {
    font-size:18px;
    line-height:35px;
  }

  @media only screen and (max-width:768px) {
    .cta-container h2 {
      font-size: 25px;
      margin-bottom: 10px;
    }
  }

`;

interface OwnProps {
  about: string;
  header: React.ReactElement;
  description: React.ReactElement;
  description2: React.ReactElement;
  cards: string[];
  timelineItems: TimelineItem[];
  navigationTabs: LinkData[];
  activeTab: string;
  BackgroundImage: React.ElementType;
  cardsTitle: string[];
  IconCardOne: React.ElementType;
  IconCardTwo: React.ElementType;
  IconCardThree: React.ElementType;
}

const Service = ({
  navigationTabs,
  activeTab,
  cards,
  description,
  description2,
  header,
  about,
  numSteps,
  timelineItems,
  BackgroundImage,
  cardsTitle,
  IconCardOne,
  IconCardTwo, 
  IconCardThree,
}: OwnProps) => {
  const ref = useRef(null);

  const handleClick = () => {
    scrollToRef(ref, 300);
  };
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        {/* <title>Last Mile Delivery Solutions | Best Logistics Company</title> */}
        <title>{intl.formatMessage({ id: "meta-title-last-mile-delivery" })}</title>
        
        <link rel="alternate" href={intl.formatMessage({ id: "ecommerce-fulfillment-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "crowdfunding-fulfillment-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "crowdfunding-fulfillment-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "crowdfunding-fulfillment-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content={intl.formatMessage({ id: "meta-description-last-mile-delivery" })} />
        <meta property="og:title" content={intl.formatMessage({ id: "meta-title-last-mile-delivery" })} />
        <meta property="og:description" content={intl.formatMessage({ id: "meta-description-last-mile-delivery" })} />
        <meta name="twitter:title" content={intl.formatMessage({ id: "meta-title-last-mile-delivery" })} />
        <meta name="twitter:description" content={intl.formatMessage({ id: "meta-description-last-mile-delivery" })} />
        <meta name="keywords" content={intl.formatMessage({ id: "meta-keywords-last-mile-delivery" })} />
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      </Helmet>
      <TabNav>
        <div className={intl.formatMessage({ id: "language" })}>
          <div className="en">
            <TabNavigator className="en" tabs={servicesLinks} activeTab={servicesLinks[3].value} />
          </div>
          <div className="zh">
            <TabNavigator className="zh" tabs={servicesLinksChinese} activeTab={servicesLinksChinese[3].value} />
          </div>
          <div className="kr">
            <TabNavigator className="kr" tabs={servicesLinksKorean} activeTab={servicesLinksKorean[3].value} />
          </div>
          <div className="ja">
            <TabNavigator className="ja" tabs={servicesLinksJapanese} activeTab={servicesLinksJapanese[3].value} />
          </div>
        </div>
      </TabNav>
      <Breadcrumbs><Link to="/">Home</Link> / <Link to="/services/">{intl.formatMessage({ id: "menu-services" })}</Link> / {intl.formatMessage({ id: "menu-last-mile-delivery" })}</Breadcrumbs>
      <Header
        BackgroundImage={BackgroundImage}
        cards={cards}
        IconCardOne={IconCardOne}
        IconCardTwo={IconCardTwo}
        IconCardThree={IconCardThree}
        cardsTitle={cardsTitle}
        description={description}
        description2={description2}
        header={header}
        about={about}
        handleClick={handleClick}
      />
      <Process innerRef={ref} numSteps={numSteps} timelineItems={timelineItems} />
      <Features>
        <div className="features-container">
          <BasePa>{intl.formatMessage({ id: "crowdfunding-fulfillment-features" })}</BasePa>
          <HeaderMedium>
            {intl.formatMessage({ id: "crowdfunding-fulfillment-features-title" })}
          </HeaderMedium>
          <div className="row">
            <div className="column-one-third">
              <img src={PickAndPack} className="features-image" alt="Pick And Pack" />
            </div>
            <div className="column-two-third">
              <h3 className="feature-subtitle">
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-subtitle-1" })}
              </h3>
              <p>
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-desc-1" })}
              </p>
              <br/>
              <p>
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-desc-1-2" })}
              </p>
              <br/>
              <p>
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-desc-1-3" })}
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="row">
            <div className="column-one-third">
              <img src={ProprietyPlatform} className="features-image" alt ="Propriety Platform" />
            </div>
            <div className="column-two-third">
              <h3 className="feature-subtitle">
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-subtitle-2" })}
              </h3>
              <p>
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-desc-2" })}
              </p>
              <br/>
              <p>
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-desc-2-2" })}
              </p>
              <ul>
                <li>
                  <h4>
                    {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-li-title-1" })}
                  </h4>
                  <p>
                    {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-li-content-1" })}
                  </p>
                </li>
                <li>
                  <h4>
                    {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-li-title-2" })}
                  </h4>
                  <p>
                    {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-li-content-2" })}
                  </p>
                </li>
                <li>
                  <h4>
                    {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-li-title-3" })}
                  </h4>
                  <p>
                    {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-li-content-3" })}
                  </p>
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="row">
            <div className="column-one-third">
              <img src={AffordablePrice} className="features-image" alt="Affordable Price Image" />
            </div>
            <div className="column-two-third">
              <h3 className="feature-subtitle">
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-subtitle-3" })}
              </h3>
              <p>
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-desc-3" })}
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="row">
            <div className="column-one-third">
              <img src={WeOfferCompetitiveRates} className="features-image" alt="We Offer Competitive Rates" />
            </div>
            <div className="column-two-third">
              <h3 className="feature-subtitle">
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-subtitle-4" })}
              </h3>
              <p>
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-desc-4" })}
              </p>
              <br/>
              <p>
                {intl.formatMessage({ id: "crowdfunding-fulfillment-feature-desc-4-1" })}
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </Features>
      <CTA>
        <img src={CTABG} className="cta-bg" alt="CTABG" />
        <div className="cta-container">
          <h2>
            {intl.formatMessage({ id: "crowdfunding-fulfillment-cta-title" })}
          </h2>
          <p>
            {intl.formatMessage({ id: "crowdfunding-fulfillment-cta-desc1" })}
          </p>
          <p>
            {intl.formatMessage({ id: "crowdfunding-fulfillment-cta-desc2" })}
          </p>
          <a href="/contact" className="cta-btn">
            {intl.formatMessage({ id: "get-in-touch" })}
          </a>
        </div>
      </CTA>
    </Layout>
  );
};

export default Service;
